import Link from "next/link"
import { Globe } from "lucide-react"
import { cn } from "@/lib/utils"

interface LogoProps {
  className?: string
}

export function Logo({ className }: LogoProps) {
  return (
    <Link 
      href="/" 
      className={cn(
        "flex items-center gap-2 text-lg font-semibold tracking-tight hover:opacity-90 transition-opacity",
        className
      )}
    >
      <Globe className="h-5 w-5 text-primary" />
      <span>
        What Is <span className="text-primary">IP</span> My Address
      </span>
    </Link>
  )
}